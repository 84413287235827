.bar {
    display: flex;
    flex-direction: column;
}

.barContainer {
    display: flex;
    border-radius: 8px;
    margin-bottom: 12px;
    overflow: hidden;
}

.barContent {
    font-size: 12px;
    line-height: 16px;
    font-weight: 100;
    color: white;
    height: 16px;
    margin-right: 2px;
    text-align: center;
}

.barDescription {
    display: flex;
}

.labelContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    align-items: center;
    display: flex;
    min-height: 20px;
    padding-bottom: 8px;

    margin-bottom: 15px;
}

.colorDot {
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    margin-right: 8px;
    width: 12px;
}

.label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.headerText {
    font-family: 'Open Sans', Arial, sans-serif; 
    font-size: 22px; 
    color: #333;
}