@import "@csstools/normalize.css";
@import "./variables.css";
@import url("https://fonts.googleapis.com/css?family=Lora:400,700|Titillium+Web:400,700");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
/* @import-normalize; */

html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0px;
  overflow: auto !important;
}
:global(body) {
  font-family: "Titillium Web", Arial, Sans-Serif;
  font-size: var(--font-size-base);
}

.wrapper {
  margin: 0;
  max-width: 100%;
  height: 100%;
}

.reactLogo {
  height: 48px;
  vertical-align: middle;
  width: 48px;
}
