.container {
  display: flex;
  padding: 1rem;
  height: 90%;
}

.contentContainer {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar {
  position: sticky;
  top: 60px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}
