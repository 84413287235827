.dropdownContainer {
  max-height: 100px;
  width: 100%;
}

.dropdown {
  padding-left: 10px !important;
  width: 100%;
}

.link {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: #0073e6;
  text-decoration: none;
  transition: color 0.3s;
}

.link:hover {
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
}

.disabled {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: gray;
  pointer-events: none;
  cursor: not-allowed;
}

.addButton {
  margin-top: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 14px;
}
