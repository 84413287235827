.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f5f5f5;
}

.truncatedValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
}

.actionButton {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    background-color: #ffb606;
    color: #fff;
    border-radius: 3px;
}

.actionButton:hover {
    background-color: #d49a05;
}