/* Sidebar container */
.sidebar {
  background-color: white; /* Initial background color */
  color: black;
  width: 100%;
}

/* Title for the sidebar */
.sidebarTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Game list */
.gameList {
  list-style-type: none;
  padding: 0;
}

/* Game item */
.gameItem {
  margin-bottom: 15px;
}

/* Game name style */
.gameName {
  font-size: 20px;
}

/* Feature list */
.featureList {
  list-style-type: none;
  padding: 0;
  margin-left: 15px; /* Space to the left compared to Game */
}

/* Feature item */
.featureItem {
  margin: 5px 0px;
}

/* Feature link style */
.featureLink {
  font-size: 18px;
  color: black;
  text-decoration: none;
  padding: 5px;
  transition: color 0.3s, background-color 0.3s; /* Smooth color transition */
  display: flex;
}

/* Active feature link style */
.featureLink.active {
  background-color: #0073e6; /* Blue color on click */
  color: white;
  width: 100%;
}
