.tableContainer {
    width: 100%;
}

.tableHeaderContainer {
    background: #3a497d !important;
}

.tableHeader {
    color: #ffffff !important;
    text-align: center !important;
}

.tableData {
    text-align: center !important;
    width: 15%;
}

.valueData {
    width: 50%;
    text-align: center !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
}

.button {
    cursor: pointer;
}