.dropdownContainer {
  max-height: 100px;
  width: 100%;
}

.inputField {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.dropdownItem {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.addButton {
  margin-top: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.addButton:hover {
  background-color: #0056b3;
}

.addedKeysContainer {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.addedKey {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.removeKeyButton {
  margin-left: 8px;
  border: none;
  background: none;
  color: #ff4d4f;
  font-size: 16px;
  cursor: pointer;
}

.removeKeyButton:hover {
  color: #d32f2f;
}