.container {
  min-width: 600px;
}

.header {
  padding: 15px;

  font-family: Lato;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  border-bottom: 1px solid #f2f2f2;
}

.row {
  display: flex;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
  padding: 20px;
}

.labelContainer {
  display: flex;
  width: 160px;
  margin-top: 5px;
}

.label {
  font-family: Lato;
  font-size: 16px;
  line-height: 24px;
}

.required {
  color: red;
  margin-left: 2px;
}


.footer {
  display: flex;
  justify-content: flex-end;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.buttonContainer {
  width: 130px;
  margin-left: 15px;
  height: 35px;
}

.errorMessage {
  color: red;
  margin-left: 20px;
}