.container {
  width: 600px;
  max-height: 90vh;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.headerActions {
  display: flex;
  gap: 10px;
}

.copyButton {
  background-color: #ffb606; 
  border: none;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.copyButton:hover {
  background-color: #d49a05; 
}

.closeButton {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #888;
}

.content {
  padding: 16px;
  max-height: 500px;
  overflow-y: auto;
  background-color: #fafafa;
}

.jsonView {
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.copyButton:disabled {
  background-color: #e0e0e0; 
  color: #9e9e9e; 
  cursor: not-allowed; 
  opacity: 0.6;
}