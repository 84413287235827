.datePicker {
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.datePickerContainer {
  width: 100% !important;
  height: 100% !important;
  max-height: 50px;
}
