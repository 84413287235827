.variantSectionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 20px;
}

.descriptionText {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: #666;
    padding: 5px 0px;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 0 20px;
}

.controlSectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border: 1px solid #666;
    padding: 15px 15px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.expandIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.rowContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.descriptionText {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: #666;
    padding: 5px 0px;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
}

.labelContainer {
    display: flex;
    width: 160px;
    margin-top: 5px;
}

.label {
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
}

.buttonContainer {
    width: 170px;
    height: 45px;
    margin-bottom: 20px;
}

.required {
    color: red;
    margin-left: 2px;
}

.headerText {
    font-family: 'Open Sans', Arial, sans-serif; 
    font-size: 22px; 
    color: #333;
}