.experimentsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 40px;
}

.headerText {
    font-family: Source Sans Pro;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;

    color: #333333;
}

.createButton {
    background: #ffffff;
    border: 2px solid #2962ff;
    box-sizing: border-box;
    border-radius: 32px;

    padding: 0px 30px;

    cursor: pointer;
    outline: none;
}

.tableContainer {
    width: 100%;
}

.container {
    display: flex;
}

.row {
    display: flex;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
}

.dropdownContainer {
    width: 200px;
}

.buttonContainer {
    height: 40px;
    margin-left: 20px;
}