.tableContainer {
    width: 100%;
}

.tableHeaderContainer {
    background: #3a497d !important;
}

.tableHeader {
    color: #ffffff !important;
    text-align: center !important;
}

.tableData {
    text-align: center !important;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
}

.button {
    cursor: pointer;
}