.mainSectionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.descriptionText {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: #666;
    padding: 5px 0px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
}

.labelContainer {
    display: flex;
    width: 200px;
    margin-top: 5px;
}

.label {
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
}

.required {
    color: red;
    margin-left: 2px;
}

.tableContainer {
    padding: 0 20px;
    margin-top: 50px;
}