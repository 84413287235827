.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    padding: 0px 20px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;

    height: 40px;
}

.button {
    padding-right: 10px;
    width: 150px;
}