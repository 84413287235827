.primaryButton {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  background-color: #5ac9fe;
  border-radius: 10px;
  border: none;
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.12));

  cursor: pointer;

  outline: none;
}

.primaryButtonText {
  width: 100%;
  height: auto;

  text-align: center;

  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  color: #ffffff;
}

.primaryButton:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
