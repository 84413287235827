.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
}

.headerText {
    font-family: 'Open Sans', Arial, sans-serif; 
    font-size: 24px; 
    font-weight: bold; 
    color: #333;
    text-transform: uppercase;
}

.descriptionText {
    font-family: 'Open Sans', Arial, sans-serif; 
    font-size: 16px;
    font-weight: normal;
    color: #666; 
}
 
.buttonContainer {
    display: flex;
    justify-content: space-between;

    height: 40px;
}

.button {
    padding-right: 10px;
    width: 150px;
}