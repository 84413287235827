.logo {
  width: 162px;
  height: 40.69px;
  margin-left: 100px;
  margin-top: 33px;
  position: relative;
}

.makeUpStudioImage {
  position: absolute;

  right: 63px;

  width: 657px;
  height: 444px;

  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.signInSection {
  padding: 17px;
  padding-top: 30px;

  width: 450px;

  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.signInText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;

  width: 100%;
  text-align: center;
}

.mobileNumberTextField {
  margin-top: 40px;
}

.roleDropdownContainer {
  margin-top: 40px;
}

.roleDropdown {
  width: 100%;
}

.rememberMeSection {
  margin-top: 20px;

  display: flex;
  flex-wrap: wrap;

  align-items: center;
}

.rememberMe {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.signInButton {
  margin: 10px;

  margin-left: 0px;
  margin-top: 40px;

  width: 100%;
  height: 60px;
}

.orSeparatorSection {
  margin-top: 120px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.orSeparator {
  width: calc(50% - 25px);
  height: 0px;
  border: 1px solid #bcbcbc;
}

.orText {
  width: 50px;

  text-align: center;

  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #bcbcbc;
}

.signUpText {
  margin-top: 20px;
  margin-bottom: 18px;

  text-align: center;

  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}

.topSignUpText {
  position: absolute;

  margin-top: 38px;
  right: 100px;

  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.beTheNextText {
  position: absolute;

  right: 180px;

  margin-top: 80px;
  width: 450px;

  text-align: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;

  color: #76a0cc;
}

.errorText {
  margin-top: 5px;
  margin-left: 10px;

  font-family: "Lato";
  font-size: 14px;

  color: red;
}

.signIn {
  height: 90%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
}

.labelContainer {
  display: flex;
  width: 160px;
  margin-top: 5px;
}

.label {
  font-family: Lato;
  font-size: 20px;
  line-height: 24px;
}

.errorText {
  margin-top: 5px;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: red;
}

.container {
  height: 100%;
}