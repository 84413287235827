@import url("https://fonts.googleapis.com/css?family=Lato");

.topBarContainer {
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  position: sticky;
  background-color: gray;
  z-index: 10;
}

.topBar {
  display: flex;
  align-items: center;

  padding-left: 50px;
  padding-right: 100px;
  padding-top: 7px;
  padding-bottom: 7px;

  height: 10%;
  width: 80%;
  min-width: 350px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.button {
  display: flex;
  justify-content: center;
  background: white;
  padding: 7px 15px;
  cursor: pointer;
  margin-right: 20px;
}

.logo {
  width: 162px;
  height: 40px;

  z-index: 100000;
}

.miniLogo {
  position: relative;
  width: 30px;
  height: 30px;
  left: 26px;
  top: 8px;

  z-index: 100000;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.avatar {
  padding-right: 10px;
}

.dropdownContainer {
  width: 250px;
}